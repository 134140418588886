.Map {
    width: 100%;
    height: 90%;
  }
  
  @media screen {
    .Map {
      border-radius: 0.75rem;
      overflow: hidden;
    }
  }
  
  .LoadingOverlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .Spinner {
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
  
    border: 0.2rem solid rgba(255, 255, 255, 0.2);
    border-left-color: #fff;
    animation: spin 1s infinite linear;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }